<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Calibration History
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
          @click="addCalibrationRecord"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Calibration</span>
      </button>
    </div>

    <div class="flex flex-wrap w-full">
      <DataTable
          v-if="calibrations.length > 0"
          class="w-full"
          :value="calibrations"
          :rows="10"
          :paginator="true"
          :rowsPerPageOptions="[5, 10, 20]"
      >
        <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            :sortable="col.sortable"
            :headerStyle="{ width: col.width }"
        >
          <template v-slot:body="slotProps">

            <template v-if="col.slotName === 'date-slot'">
              <span>
                {{ slotProps.data.date ? formatDate(slotProps.data.date) : "N/A" }}
              </span>
            </template>

            <template v-else-if="col.slotName === 'next-date-slot'">
              <span
                  class="px-2 rounded-lg"
                  :class="{
                  'bg-yellow-200 text-yellow-700': !slotProps.data.next_date,
                  'bg-green-200 text-green-700': slotProps.data.next_date
                }"
              >
                {{ slotProps.data.next_date ? formatDate(slotProps.data.next_date) : "N/A" }}
              </span>
            </template>



            <template v-else-if="col.slotName === 'action-slot'">
              <div class="flex space-x-4">
                <AppButton
                    color="blue"
                    label=""
                    :icon="true"
                    @click.native.stop="openEditCalibration(slotProps)"
                >
                  <template v-slot:icon>
                    <Icon iconType="edit" />
                  </template>
                </AppButton>
                <AppButton
                    color="red"
                    label=""
                    :icon="true"
                    @click.native.stop="confirmDeleteCalibration(slotProps)"
                >
                  <template v-slot:icon>
                    <Icon iconType="delete" />
                  </template>
                </AppButton>
              </div>
            </template>

            <template v-else>
              {{ slotProps.data[col.field] || "N/A" }}
            </template>
          </template>
        </Column>

        <template #empty>
          <p class="mx-auto my-16">There are no calibration records available.</p>
        </template>
      </DataTable>
    </div>

    <portal to="overlay-outlet">
      <panel
          :showing="addEditCalibrationPanelOpen"
          @close="closeAddEditCalibration"
          :title="editingCalibration.calibration_id ? 'Edit Calibration' : 'Add Calibration'"
      >
        <AddCalibrationRecord
            :calibration_id="selectedCalibrationId"
            :initial_calibration="selectedCalibration"
            :equipment_id="equipment_id"
            @refreshEquipment="onCalibrationSaved"
        />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
          :showingConfirm="confirmAlert.showing"
          :message="confirmAlert.message"
          @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";
import notificationMixin from "@/mixins/notificationMixin";

const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddCalibrationRecord = () => import("@/components/Equipment/AddCalibration.vue");

export default {
  mixins: [notificationMixin],
  name: "CalibrationHistory",
  components: {
    AppButton,
    Icon,
    Notification,
    ConfirmAlert,
    Panel,
    AddCalibrationRecord,
  },
  props: {
    calibrations: Array,
    equipment_id:String,
  },
  data() {
    return {
      addEditCalibrationPanelOpen: false,
      editingCalibration: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {field: "date", header: "Calibration Date", sortable: true, width: "20%", slotName: "date-slot" },
        {field: "next_date", header: "Next Calibration Date", sortable: true, width: "20%", slotName: "next-date-slot"},
        {field: "certificate_number", header: "Certificate Number", sortable: true, width: "20%"},
        {
          field: "action",
          header: "Actions",
          sortable: false,
          width: "10%",
          custom: true,
          slotName: "action-slot",
        },
      ],
      selectedCalibrationId: "",
      selectedCalibration: null,
    };
  },
  methods: {
    formatDate(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format("DD-MM-YYYY");
    },

    addCalibrationRecord() {
      this.editingCalibration = {};
      this.selectedCalibrationId = "";
      this.selectedCalibration = null;
      this.addEditCalibrationPanelOpen = true;
    },

    async onCalibrationSaved() {
      this.closeAddEditCalibration();
      await this.refreshEquipment();
    },

    closeAddEditCalibration() {
      this.addEditCalibrationPanelOpen = false;
    },

    async refreshEquipment() {
      this.$emit("refreshEquipment");
    },

    openEditCalibration(selectedRow) {
      if (selectedRow && selectedRow.data && selectedRow.data.calibration_id) {
        this.selectedCalibrationId = selectedRow.data.calibration_id;
        this.selectedCalibration = selectedRow.data;
        this.addEditCalibrationPanelOpen = true;
      }
    },

    async confirmDeleteCalibration(selectedRow) {
      if (!selectedRow || !selectedRow.data || !selectedRow.data.calibration_id) return;

      const calibrationId = selectedRow.data.calibration_id;
      const confirmation = await this.confirmAction({ message: "Do you want to delete this calibration?", header: "Confirm Action", acceptLabel: "Yes", rejectLabel: "No" });

      if (confirmation) {
        try {
          if (calibrationId) {
            await this.EquipmentService.deleteCalibration(this.equipment_id,calibrationId);
            this.notifySuccess("Calibration deleted successfully");
            await this.refreshEquipment();
          } else {
            this.notifyError("Calibration ID is missing");
          }
        } catch (error) {
          this.notifyError("Couldn't delete the calibration");
          console.error(error);
        }
      }
    },
  },
};
</script>
